// import React, { useImperativeHandle, forwardRef, useRef } from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// const RMA = forwardRef((props, ref) => {
//   const { infotipData } = props;
//   const formRef = useRef(null);

//   // Function to generate and download PDF
//   const handleDownload = () => {
//     if (formRef.current) {
//       html2canvas(formRef.current, { scale: 2 }).then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");
//         const pdf = new jsPDF("p", "mm", "a4");
//         const imgWidth = 190; // Fit within A4 size
//         const imgHeight = (canvas.height * imgWidth) / canvas.width;

//         pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
//         pdf.save("RMA_Form.pdf");
//       });
//     }
//   };

//   // Expose the download function to the parent
//   useImperativeHandle(ref, () => ({
//     downloadForm: handleDownload,
//   }));

//   return (
//     <div>
//       {/* Form Container for PDF */}
//       <div ref={formRef} style={styles.container}>
//         <h2 style={styles.header}>TV Repair Service Completion Form</h2>
//         <p><strong>RMA Number:</strong> {infotipData?.rmaNumber || "N/A"}</p>
//         <p><strong>Date:</strong> {infotipData?.date || "N/A"}</p>

//         {/* TV Details Section Header with Blue Background */}
//         <h3 style={styles.tvDetailsHeader}>TV Details</h3>
//         <table style={styles.tvDetailsTable}>
//           <tbody>
//             <tr><td>TV Brand</td><td>{infotipData?.tvBrand || "N/A"}</td></tr>
//             <tr><td>Model Name</td><td>{infotipData?.modelName || "N/A"}</td></tr>
//             <tr><td>Serial Number</td><td>{infotipData?.serialNumber || "N/A"}</td></tr>
//             <tr><td>Warranty Status</td><td>{infotipData?.warrantyStatus || "N/A"}</td></tr>
//             <tr><td>Purchase Date</td><td>{infotipData?.purchaseDate || "N/A"}</td></tr>
//             <tr><td>Customer Country</td><td>{infotipData?.customerCountry || "N/A"}</td></tr>
//           </tbody>
//         </table>

//         {/* Work Order Description Section */}
//         <h3 style={styles.subHeader}>Work Order Description</h3>
//         <table style={styles.table}>
//           <thead>
//             <tr><th>Field</th><th>Details</th></tr>
//           </thead>
//           <tbody>
//             <tr><td>Handling Type</td><td>{infotipData?.handlingType || "N/A"}</td></tr>
//             <tr><td>Service Solution</td>
//               <td>
//                 <ul>
//                   <li><input type="checkbox" checked={infotipData?.serviceRepaired ?? false} readOnly /> TV is repaired</li>
//                   <li><input type="checkbox" checked={infotipData?.serviceTakenAway ?? false} readOnly /> TV is taken away by serviceman for further repair</li>
//                 </ul>
//               </td>
//             </tr>
//             <tr><td>Service Solution Detail</td><td>{infotipData?.serviceDetail || "N/A"}</td></tr>
//           </tbody>
//         </table>

//         <p>For general terms and conditions, visit <a href="https://www.philips.pl/c-w/wsparcie-konsumenta/gwarancja/warranty-bl.html">Philips website</a>.</p>
//       </div>
//     </div>
//   );
// });

// // Styles for consistent formatting
// const styles = {
//   container: {
//     padding: "20px",
//     background: "#fff",
//     color: "#000",
//     fontFamily: "Arial, sans-serif",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     maxWidth: "600px",
//     margin: "auto",
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: "15px",
//     fontSize: "18px",
//     textTransform: "uppercase",
//   },
//   subHeader: {
//     marginTop: "10px",
//     fontSize: "16px",
//     textDecoration: "underline",
//   },
//   tvDetailsHeader: {
//     backgroundColor: "#007BFF",  // Blue background for "TV Details" header
//     color: "#fff",
//     padding: "10px",
//     borderRadius: "5px",
//     marginBottom: "10px",
//     textAlign: "center",
//     fontSize: "18px",
//   },
//   tvDetailsTable: {
//     width: "100%",
//     borderCollapse: "collapse",
//     marginTop: "10px",
//     border: "1px solid #ddd",  // Keep normal border for table
//   },
//   table: {
//     width: "100%",
//     borderCollapse: "collapse",
//     marginTop: "10px",
//     border: "1px solid #ddd",
//   },
//   th: {
//     backgroundColor: "#f2f2f2",
//     padding: "8px",
//     textAlign: "left",
//   },
//   td: {
//     padding: "8px",
//     textAlign: "left",
//   },
// };

// export default RMA;











import React, { useImperativeHandle, forwardRef, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const RMA = forwardRef((props, ref) => {
  const { infotipData } = props;
  console.log(infotipData,"in RMA fromm??")
  const formRef = useRef(null);
  const [serviceRepaired, setServiceRepaired] = useState(infotipData?.serviceRepaired ?? false);
  const [serviceTakenAway, setServiceTakenAway] = useState(infotipData?.serviceTakenAway ?? false);

  const handleDownload = () => {
    if (formRef.current) {
      html2canvas(formRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
        pdf.save("RMA_Form.pdf");
      });
    }
  };

  useImperativeHandle(ref, () => ({
    downloadForm: handleDownload,
  }));

  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "repaired") {
      setServiceRepaired(!serviceRepaired);
    } else if (checkboxName === "takenAway") {
      setServiceTakenAway(!serviceTakenAway);
    }
  };

  return (
    <div style={styles.page}>
      <div ref={formRef} style={styles.container}>
        <div style={styles.header}>
          {/*  Important: Replace 'your_logo.png' with the actual path to your logo */}
          <img src="your_logo.png" alt="TPV Logo" style={styles.logo} onError={(e) => e.target.src = 'placeholder_logo.png'}/> {/* Placeholder for missing logo */}
          <h2>TV Repair Service Completion Form</h2>
        </div>

        <div style={styles.infoRow}>
          <p><strong>RMA Number:</strong> {infotipData?.rmaNumber || "N/A"}</p>
          <p><strong>Date:</strong> {infotipData?.date || "N/A"}</p>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>TV Detail</div>
          <table style={styles.tvDetailsTable}>
            <tbody>
              {Array.isArray(infotipData?.tvDetails) ? (
                infotipData.tvDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.label}</td>
                    <td>{detail.value}</td>
                  </tr>
                ))
              ) : (
                <>
                  <tr><td>TV Brand</td><td>{infotipData?.tvBrand || "N/A"}</td></tr>
                  <tr><td>Model Name</td><td>{infotipData?.modelName || "N/A"}</td></tr>
                  <tr><td>Serial Number</td><td>{infotipData?.serialNumber || "N/A"}</td></tr>
                  <tr><td>Warranty Status</td><td>{infotipData?.warrantyStatus || "N/A"}</td></tr>
                  <tr><td>Purchase Date</td><td>{infotipData?.purchaseDate || "N/A"}</td></tr>
                  <tr><td>Customer Country</td><td>{infotipData?.customerCountry || "N/A"}</td></tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Work Order Description</div>
          <table style={styles.table}>
            <tbody>
              <tr><td>Handling Type</td><td>{infotipData?.handlingType || "N/A"}</td></tr>
              <tr>
                <td>Service Solution</td>
                <td>
                  <div style={styles.checkboxList}>
                    <label>
                      <input type="checkbox" checked={serviceRepaired} onChange={() => handleCheckboxChange("repaired")} /> TV is repaired
                    </label>
                    <label>
                      <input type="checkbox" checked={serviceTakenAway} onChange={() => handleCheckboxChange("takenAway")} /> TV is taken away by serviceman for further repair
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Service Solution Detail</div>
          <table style={styles.table}>
            <tbody>
              {/* <tr><td>Handling Type</td><td>{infotipData?.handlingType || "N/A"}</td></tr>
              <tr>
                <td>Service Solution</td>
                <td>
                  <div style={styles.checkboxList}>
                    <label>
                      <input type="checkbox" checked={serviceRepaired} onChange={() => handleCheckboxChange("repaired")} /> TV is repaired
                    </label>
                    <label>
                      <input type="checkbox" checked={serviceTakenAway} onChange={() => handleCheckboxChange("takenAway")} /> TV is taken away by serviceman for further repair
                    </label>
                  </div>
                </td>
              </tr> */}
              
            </tbody>
          </table>
        </div>

        <p style={styles.terms}>For general terms and conditions of the manufacturer warranty, please refer to Philips website <a href="https://www.philips.pl/c-w/wsparcie-konsumenta/gwarancja/warranty-bl.html" target="_blank" rel="noopener noreferrer">https://www.philips.pl/c-w/wsparcie-konsumenta/gwarancja/warranty-bl.html</a>.</p>

        <div style={styles.footer}>
  <div style={styles.signatureRow}> {/* Wrap signatures in a row */}
    <div style={styles.signatureBlock}>
      <p>Date</p>
      <p>Signature (Workshop)</p>
    </div>
    <div style={styles.signatureBlock}>
      <p>Date</p>
      <p>Signature (Customer)</p>
    </div>
  </div> {/* End signature row */}

  <div style={styles.companyInfo}> {/* Company info below */}
    <p>Authorized Servicer of TP Vision for Philips TV</p>
    <p>TPV Displays Polska Sp. z o.o. ul. Złotego Smoka 9, 66-400 Gorzów Wielkopolski, Polska</p>
    <p>Tel. +48 xxx XXX XXX  email: xxxxxx@tpv-tech.com</p>
  </div>
</div>
      </div>
    </div>
  );
});

const styles = {
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: '100vh',
      backgroundColor: '#f4f4f4',
    },
    container: {
      padding: "20px",
      background: "#fff",
      color: "#000",
      fontFamily: "Arial, sans-serif",
      border: "1px solid #ddd",
      borderRadius: "5px",
      maxWidth: "800px",
      width: '100%',
      boxSizing: 'border-box',
      margin: "20px 0",
    },
    header: {
      textAlign: "center",
      marginBottom: "15px",
      fontSize: "18px",
      textTransform: "uppercase",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      maxWidth: '200px',
      marginBottom: '10px',
      display: 'block',
      margin: '0 auto',
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '15px',
      borderBottom: '1px solid #ddd',
      paddingBottom: '10px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      color: '#ffffff',
      marginBottom: '5px',
      backgroundColor: '#337ab7', // Light gray background
      padding: '5px 10px',      // Add padding
      borderRadius: '3px',       // Rounded corners
    },
    tvDetailsTable: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "10px",
      border: '1px solid #ddd' // Add table border
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "10px",
    },
    td: {
      padding: "8px",
      textAlign: "left",
      border: '1px solid #ddd' // Add cell border
    },
    noBorder: {
      border: 'none',  // Remove all borders
      padding: '8px',  // Maintain padding for spacing
    },
    checkboxList: {
      display: 'flex',
      flexDirection: 'column',
    },
    terms: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '14px',
    },
    footer: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',  // Stack elements vertically
        alignItems: 'flex-start', // Align to the left
        borderTop: '1px solid #ddd',
        paddingTop: '10px',
      },
      signatureRow: { // New style for the signature blocks row
        display: 'flex',
        justifyContent: 'space-between', // Space between signature blocks
        width: '100%', // Take full width to distribute space correctly
        marginBottom: '10px' // Space between signature blocks and company info
      },
      signatureBlock: {
        // No need for margin-bottom here
      },
    companyInfo: {
      marginTop: '30px', // Add some spacing
    },
  };


export default RMA;