import axios from "axios";
import { api } from '../axios/AxiosInstances';
import React, { useRef, useState } from "react";
import { Box, CircularProgress, Backdrop } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "./ConfirmationModal";


function PickUp({ detailedData, textOptions, getPythonData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date();
  const fileInputRef = useRef(null);
  const [attachLoading, setAttachLoading] = useState(false)
  const [selectedValues, setSelectedValues] = useState({});
  const [packageCondition, setPackageCondition] = useState("");
  const [accessories, setAccessories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [ownTransportToggle, setOwnTransportToggle] = useState(false);
  const [reqWHToggle, setReqWHToggle] = useState(false);
  const [plannedDateTime, setPlannedDateTime] = useState("");
  const [reqDateTime, setReqDateTime] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [DateTimearrived, setDateTimearrived] = useState("");
  const [ftpSuccess, setFtpSuccess] = useState(false); 

  const handleOwnTransport = () => {
    setOwnTransportToggle(true);
  };

  const handleOwnTransportCancel = () => {
    setOwnTransportToggle(false);
  };

  const handleReqWHToggle = () => {
    setReqWHToggle(true);
  };

  const handleReqWHToggleCancel = () => {
    setReqWHToggle(false);
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };



  const handlePackageConditionChange = (event) => {
    setPackageCondition(event.target.value);
  };

  const handleAccessoryChange = (event) => {
    const newAccessories = [...accessories];
    if (event.target.checked) {
      newAccessories.push(event.target.value);
    } else {
      const index = newAccessories.indexOf(event.target.value);
      newAccessories.splice(index, 1);
    }
    setAccessories(newAccessories);
    // console.log(accessories)
  };

  var expanded = false;

  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoUpload = (event) => {
    const fileTypes = [".jpg", ".jpeg", ".gif", ".pdf", ".png"]
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / (1024 * 1024)
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!fileTypes.includes("." + fileExtension)) {
        window.alert(`${fileExtension} file is not allowed!`)
        event.target.value = '';
        return
      }

      if (fileSize > 3) {
        window.alert("File is larger than 3MB")
        event.target.value = '';
        return;
      }
      const newPhotos = [...photos];

      const reader = new FileReader(); // FileReader is instantiated.
      reader.onloadend = () => { // onloadend handler is defined but not yet executed.
        const base64String = reader.result.split(',')[1];
        newPhotos.push({
          fileName: file.name,
          // filePath: file.path,
          dateTime: new Date().toLocaleString(),
          fileExt: fileExtension,
          content: base64String,
        });
        setPhotos(newPhotos);

        event.target.value = '';
      };
      reader.readAsDataURL(file); // readAsDataURL method starts reading the file.
    } else {
      console.log("No file selected");
    }
  };

  const handleCancel = (delFile) => {
    const newPhotos = photos.filter(photo => photo.fileName !== delFile);
    setPhotos(newPhotos);
  }

  // function getRandomUniqueNumber(min, max, existingNumbers) {
  //   let randomNumber;
  //   do {
  //     randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   } while (existingNumbers.includes(randomNumber));
  //   return randomNumber..toString();
  // }

  function getUniqueIndex(arr) {
    const numberArray = arr.map(str => parseInt(str, 10));
    // const numberArray = arr.map(Number);
    const maxNumber = Math.max(...numberArray);
    return (maxNumber + 1).toString();
  }

  const handleConfirm = async (fileName, fileExtension, content, textValue) => {
    // console.log(filePath)
    setAttachLoading(true)
    try {
      const orderIssueNr = detailedData.OrderIssueNr
      const orderName = detailedData.OrderName
      const uniqueIndex = (getUniqueIndex(detailedData.docIndexes))
      // const uniqueIndex = getRandomUniqueNumber(500, 899, detailedData.docIndexes);
      const docName = `${orderName}-${uniqueIndex}.${fileExtension}`;

      const response = await api.post("/sendAttachment", { orderIssueNr, uniqueIndex, docName, content, textValue })
      // console.log(response)
      if (response.data.split('|')[0].trim() === "0") {
        setAttachLoading(false)
        window.alert("Attachment sent to Infotip")
        handleCancel(fileName)
        getPythonData(detailedData.OrderName)
      } else {
        setAttachLoading(false)
        window.alert("Error sending the attachment1")
      }
    } catch (error) {
      setAttachLoading(false)
      window.alert("Error sending the attachment")
      console.log("error sending the attachment")
    }
  }

  const handleSelectChange = (i, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [i]: value
    }));
  };


  const handleDateTimeChange = (event) => {
    setPlannedDateTime(event.target.value);
  };

  const handlereqDateTimeChange = (event) => {
    setReqDateTime(event.target.value);
  };

  const handleDateTimearrived = (event) => {
    setDateTimearrived(event.target.value);
  };

  const handleConfirm1 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen1(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 351;

    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse ,"response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
    } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
    }
    
    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };

  const handleAlert1 = () => {
    if (plannedDateTime) {

      setIsOpen1(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Planned Date is Empty")
    }
  }

  const handleCancel1 = (e) => {
    setIsOpen1(false);
    // setToggleNewModelName(false);
  };

  const handleConfirm2 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen2(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 351;

    // Fetch updated data
    // try {
    //   console.log("calling update api....")
    //   const updateResponse = await api.post("/updateInfotipaction", {
    //     OrderIssueNr: OrderIssueNr, // Use the function parameter directly
    //     updatedValue: updatedValue,
    //     updateType: updateType
    //   });

    //   // const updateStatus = updateResponse.data.split('|')[2].trim();
    //   console.log(updateResponse, "response from status update api");
    //   if (updateResponse.data.success == true) {
    //     console.log("Success:", updateResponse.data);
    //     alert("Order updated successfully!");
    //   } else {
    //     console.error("Failure:", updateResponse.data.error);
    //     alert("Failed to update order. Please try again.");
    //   }

    // } catch (error) {
    //   console.error("Error updating infotip:", error);
    //   alert("Failed to update order. Please try again.");
    // }

    try {
      console.log("Calling FTP update API....");
      const ftpResponse = await api.post("/updateftp", {
        updatedata: detailedData
      });
    
      console.log(ftpResponse, "Response from FTP update API");
    
      if (ftpResponse.data.success === true) {
        console.log("FTP Update Successful. Now calling update API...");
    
        // Call updateInfotipaction API after successful FTP update
        const updateResponse = await api.post("/updateInfotipaction", {
          OrderIssueNr: OrderIssueNr,
          updatedValue: updatedValue,
          updateType: updateType,
        });
    
        console.log(updateResponse, "Response from status update API");
    
        if (updateResponse.data.success === true) {
          alert("Order and FTP updated successfully!");
          //set pickup flag
          setFtpSuccess(true)
        } else {
          console.error("Update API Failure:", updateResponse.data.error);
          alert("FTP update successful, but order update failed.");
        }
      } else {
        console.error("FTP Update Failure:", ftpResponse.data.error);
        alert("FTP update failed. Please try again.");
      }
    } catch (error) {
      console.error("Error updating:", error);
      alert("An error occurred. Please try again.");
    }
  
  };

  const handleAlert2 = () => {
    if (reqDateTime) {

      setIsOpen2(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Request WH Date is Empty")
    }
  }

  const handleCancel2 = (e) => {
    setIsOpen2(false);
    // setToggleNewModelName(false);
  };

  const handleConfirm3 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen3(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 130;

    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse ,"response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
        // onSuccess();
    } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
    }
    
    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };

  const handleAlert3 = () => {
    if (DateTimearrived) {

      setIsOpen3(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Date of Defective is Empty")
    }
  }

  const handleCancel3 = (e) => {
    setIsOpen3(false);
    // setToggleNewModelName(false);
  };

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Pickup address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Pickup:</h6>
        <div className="plan-request-date" style={{ display: "flex", gap: "20px" }}>
          {/* Own Transport Section */}
          <div className="plan-date" style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
            <button className="curPo confirm" onClick={handleOwnTransport}>
              Own Transport
            </button>

            {ownTransportToggle && (
              <>
                <p>Planned Date & Time:</p>
                <input type="date" className="select-styled" value={plannedDateTime} onChange={handleDateTimeChange} />
                <button className="curPo confirm" onClick={handleAlert1}>Confirm (351)</button>

                <ConfirmationModal
                  isOpen={isOpen1}
                  message="Update Status to Infotip?"
                  onConfirm={() => handleConfirm1(detailedData.OrderIssueNr)}
                  onCancel={handleCancel1}
                />

                <button className="curPo cancel" onClick={handleOwnTransportCancel}>
                  Cancel
                </button>
                <button className="curPo confirm selected-date">
                  Change planned date & time
                </button>
              </>
            )}
          </div>

          {/* Request WH Pickup Section */}
          <div className="request-wh" style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
            <button className="curPo confirm" onClick={handleReqWHToggle}>
              Request WH Pickup
            </button>

            {reqWHToggle && (
              <>
                <p>Request Date & Time:</p>
                <input type="date" className="select-styled" value={reqDateTime} onChange={handlereqDateTimeChange} />
                <button className="curPo confirm" onClick={handleAlert2}>Confirm (351)</button>

                <ConfirmationModal
                  isOpen={isOpen2}
                  message="Update Status to Infotip?"
                  onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
                  onCancel={handleCancel2}
                />
                <button className="curPo cancel" onClick={handleReqWHToggleCancel}>
                  Cancel
                </button>
                {ftpSuccess && (
                <button className="curPo cancel" >
                  Pickup Requested
                </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <br></br>

      <div >
        <div style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
          <h6>Date of Defective TV arrived: </h6>

          <input type="date" className="select-styled" value={DateTimearrived} onChange={handleDateTimearrived} />
          <button className="curPo confirm" onClick={handleAlert3}>Confirm (130)</button>

          <ConfirmationModal
            isOpen={isOpen3}
            message="Update Status to Infotip?"
            onConfirm={() => handleConfirm3(detailedData.OrderIssueNr)}
            onCancel={handleCancel3}
          />

          {/* <button className="curPo cancel" onClick={handleOwnTransportCancel}>
            Cancel
          </button> */}
        </div>
        <br></br>

        <div style={{ gap: "50px" }}>
          <h6>
            Condition of Defective TV packaging
          </h6>

          <div style={{ display: "grid", gridTemplateColumns: "200px 200px auto", gap: "30px", marginBottom: "10px" }}>
            <label htmlFor="packageCondition">Package condition:</label>
            <select
              className="select-styled"
              id="packageCondition"
              value={packageCondition}
              onChange={handlePackageConditionChange}
            >
              <option value="">select condition</option>
              <option value="good">Original packaging: good</option>
              <option value="damaged">Original packaging: damaged</option>
              <option value="good">Other packaging: good</option>
              <option value="damaged">Other packaging: damaged</option>

            </select>
            <div>
              <label htmlFor="comment">Comment:</label>
              <input className="input-styled" style={{ width: "25%" }} id="comment"></input>
            </div>
            <label>Accessories:</label>
            <div>
              <div className="selectBox" onClick={showCheckboxes}>
                <select className="select-styled">
                  <option value="">Select an option</option>
                </select>
                <div className="overSelect"></div>
              </div>
              <div id="checkboxes">
                <label htmlFor="remote"><input type="checkbox" id="one" value="remote" onChange={handleAccessoryChange} />Remote Control</label>
                <label htmlFor="stand"><input type="checkbox" id="two" value="stand" onChange={handleAccessoryChange} />Stand with screws</label>
                <label htmlFor="powerCable"><input type="checkbox" id="three" value="power cable" onChange={handleAccessoryChange} />Power cable</label>
                <label htmlFor="powerCable"><input type="checkbox" id="three" value="others" onChange={handleAccessoryChange} />Others</label>
                <label htmlFor="powerCable"><input type="checkbox" id="three" value="none" onChange={handleAccessoryChange} />None</label>

              </div>
            </div>
            <div>
              <label htmlFor="comment">Comment:</label>
              <input className="input-styled" style={{ width: "25%" }} id="comment"></input>
            </div>
          </div>
        </div>

        {/* <div>
          <label htmlFor="photoUpload">Add photo:</label>
          <input
            type="file"
            id="photoUpload"
            accept=".jpg, .jpeg, .gif, .pdf, .png"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handlePhotoUpload}
          />
          <button className="curPo confirm" onClick={handleButtonClick}>Choose File</button>
        </div> */}
        {/* {photos.length !== 0 && (
          <div style={{ display: "flex" }}>
            <h6>Photos</h6>
            <table className="photos-table" style={{ borderSpacing: 20 }}>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Date Time</th>
                  <th>Assign Infotip Attachment Type</th>
                  <th>Send to Infotip</th>
                </tr>
              </thead>
              <tbody>
                {photos.map((photo, i) => {
                  const textValue = selectedValues[i] || '';
                  return (
                    <tr key={i}>
                      <td>{photo.fileName}</td>
                      <td>{photo.dateTime}</td>
                      <td>
                        <select className="select-styled" style={{ marginLeft: "-5px" }} required value={textValue} onChange={(e) => handleSelectChange(i, e.target.value)}>
                          <option value="">Select</option>
                          {textOptions && Object.keys(textOptions).length > 0 && Object.entries(textOptions).map(([key, value]) => (
                            <option key={key} value={value}>{key}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <button className="confirm curPo" type="button" onClick={() => { handleConfirm(photo.fileName, photo.fileExt, photo.content, textValue) }}>Confirm</button>
                        <button className="curPo cancel" onClick={() => handleCancel(photo.fileName)} type="button">Cancel</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )} */}
      </div>

      {/* {attachLoading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            sx={{
              display: 'flex',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'grey.500' }} />
          </Box>
        </Backdrop>} */}
    </div>
  );
}

export default PickUp;
