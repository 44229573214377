import React, { useState ,useRef } from "react";
import DatePicker from "react-datepicker";
import ConfirmationModal from "./ConfirmationModal";
import axios from "axios";
import { api } from '../axios/AxiosInstances';
// import "react-datepicker/dist/react-datepicker.css";
import RMA from "./RMA_Form";
import { blue } from "@mui/material/colors";

function HomeVisit({ detailedData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateTimeInputVisible, setIsDateTimeInputVisible] = useState(false);
  const [plannedDateTime, setPlannedDateTime] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpenEmailSMS , setIsOpenEmailSMS] = useState(false);
  const currentDate = new Date();
  const [email, setEmail] = useState(detailedData.Email)
  const [mobile, setMobile] = useState(detailedData.Mobil)
  const [informByEmail, setInformByEmail] = useState(false);
  const [informByMobile, setInformByMobile] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [newModelName, setNewModelName] = useState("");
  const [toggleNewModelName, setToggleNewModelName] = useState(false);
  const [updateType, setUpdateType] = useState("")
  const [updatedValue, setUpdatedValue] = useState("")
  const [orderIssueNr, setOrderIssueNr] = useState(null);
  const [detailedData1, setDetailedData] = useState(null);
  const rmaRef = useRef(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInformByEmail = (e) => {
    const checked = e.target.checked;
    setInformByEmail(checked);
    if (checked) {
      setInformByMobile(false); // Uncheck SMS checkbox
    }
  };

  const handleInformByMobile = (e) => {
    const checked = e.target.checked;
    setInformByMobile(checked);
    if (checked) {
      setInformByEmail(false); // Uncheck email checkbox
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleAlert = () => {
    if (informByEmail) {
      setModalMessage("Send Mail?");
    } else if (informByMobile) {
      setModalMessage("Send SMS?");
    } else {
      setModalMessage("No option selected.");
    }
    setIsOpenEmailSMS(true);
  };

  const handleConfirm1 = async (mailId) => {
    //by email
    setIsOpenEmailSMS(false);
    if (informByEmail) {
      console.log(`Sending email to: ${email}`);
      const mailData = {
        to: email, // Replace with actual recipient email
        subject: `Philips TV home service confirmation for ${detailedData.OrderName}`,
        html: `
          <p>Dear customer,</p>
          <p>Workorder Number:<strong> ${detailedData.OrderName}</strong></p>
          <p>We have arranged a home service for your Philips TV ${detailedData.ModelName} as below:</p>
          <p>Home service date & time: ${detailedData.PlannedDateTime}</p>
          <p>Home service location: ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}</p>
          <p>Your contact number: ${detailedData.Phone}</p>
          <p>Our technician will call you on the date around 30 minutes before the visit.</p>
          <p>If you have any questions or you won’t be available at the above time, please contact us through xxxx. Please do not reply to this email as it is a no-response email.</p>
          <p>Thank you.</p>
          <br/>
          <p>Best regards,</p>
          <p>TPV Displays Sp. z o. o.</p>
          <p>Authorized Servicer of TP Vision for Philips TV</p>
          <p>ul. Złotego Smoka 9, 66-400 Gorzów Wielkopolski, Polska</p>
          <p>Tel. +48 xxx xxx xxx</p>
          <p>Email: europe.repair@tpv-tech.com</p>
        `,
      };
      try {
        await api.post('/sendMail', { mailData });
        // console.log(response.data);
        window.alert("Mail sent sussessfully!.")
      } catch (error) {
        console.error("error sending mail", error);
      }
    }
    //by sms
    if (informByMobile) {
      console.log(`Sending SMS to: ${mobile}`);
      window.alert("SMS sent sussessfully!.")
    }
  };

  const handleCancel = (e) => {
    setIsOpenEmailSMS(false);
  };

  const handleCancel1 = (e) => {
    setIsOpen1(false);
    setToggleNewModelName(false);
  };

  const toggleDateTimeInput = () => {
    setIsDateTimeInputVisible(!isDateTimeInputVisible);
  };

  const handleDateTimeChange = (event) => {
    setPlannedDateTime(event.target.value);
  };

  
  const handleAlert2 = () => {
    if (plannedDateTime) {
     
      setIsOpen1(true)
      console.log(isOpen,isOpen1,"update sattus???")
    } else {
      alert("Planned Date is Empty")
    }
  }

  const handleConfirm2 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen1(false);
    setToggleNewModelName(false);
    
    const updateType = "OrderState";
    const updatedValue = 181;

    // Fetch updated data
    try {
      console.log("calling update api....")
        const updateResponse = await api.post("/updateInfotipaction", {
            OrderIssueNr: OrderIssueNr, // Use the function parameter directly
            updatedValue: updatedValue,
            updateType: updateType
        });

        const updateStatus = updateResponse.data.split('|')[2].trim();
        console.log(updateStatus);
    } catch (error) {
        console.error("Error updating infotip:", error);
    }
};


const handleRmaClick = () => {
  // Set the detailedData (either fetched or created dynamically)
  const newDetailedData = {
    rmaNumber: detailedData.OrderName,
    date: detailedData.OrderDate,
    tvBrand: 'Philips',
    modelName: detailedData.ProductName,
    serialNumber: detailedData.SerialNumber,
    warrantyStatus: 'Valid',
    purchaseDate: '2023-06-10',
    customerCountry: detailedData.CountryId,
    handlingType: detailedData.HandlingType,
    serviceRepaired: true,
    serviceTakenAway: false,
    serviceDetail: 'Repaired screen issue.'
  };
  setDetailedData(newDetailedData); // Pass the data to state

  // Access the RMA component method via ref and trigger download
  if (rmaRef.current) {
    rmaRef.current.downloadForm(); // This will trigger the form download
  }
};

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Home Visit address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div style={{ marginLeft: "2%" }} className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Home Visit:</h6>
        <div>
          {/* <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
            <p>Planned Date & Time:</p>
            {isDateTimeInputVisible && (
              <input
                type="date"
                className="select-styled"
                value={plannedDateTime}
                onChange={handleDateTimeChange}
              />
            )}
            <button className="curPo confirm" onClick={handleAlert2}>Confirm (181 + 351) </button>
            <ConfirmationModal
              isOpen={isOpen}
              message="Update Status to Infotip?"
              onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
              onCancel={handleCancel}
            />
            <button className="curPo cancel">Cancel</button>

            <button
              className="curPo confirm selected-date"
              onClick={toggleDateTimeInput}
            >
              Change planned date & time
            </button>
          </div> */}

        <div>
            <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
                <p>Planned Date & Time:</p>
                <input type="date" className="select-styled" value={plannedDateTime} onChange={handleDateTimeChange} />

                <button className="curPo confirm" onClick={handleAlert2}>Confirm (181 + 351)</button>

                <ConfirmationModal
                    isOpen={isOpen1}
                    message="Update Status to Infotip?"
                    onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
                    onCancel={handleCancel1}
                />

                <button className="curPo cancel" onClick={handleCancel}>Cancel</button>

              <button
                className="curPo confirm selected-date"
                onClick={toggleDateTimeInput}
              > Change planned date & time</button>

            </div>
        </div>

          <div style={{ display: "flex", gap: '20px' }}>
            <span>Inform Customer for home visit details: </span>
            <div>
              {/* <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto" }}> */}
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                <input className="checkbox-styled" type="checkbox" onChange={handleInformByEmail} checked={informByEmail} />
                <span>by email</span>
                <span>Email:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black", flex: "1", minWidth: "200px" }} type="email" id="email" onChange={handleEmailChange} value={email} />
                <button style={{ marginLeft: "10px" }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button>
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                <input className="checkbox-styled" type="checkbox" onChange={handleInformByMobile} checked={informByMobile} />
                <span>by SMS</span>
                <span>Mobile:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black", flex: "1", minWidth: "200px" }} type="tel" id="myInput" onChange={handleMobileChange} value={mobile} />
                <button style={{ marginLeft: "10px" }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button>
              </div>

              {/* <button style={{ margin: '20px' }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button> */}
              <ConfirmationModal
                isOpen={isOpenEmailSMS}
                message={modalMessage}
                onConfirm={() => handleConfirm1()}
                onCancel={handleCancel}
              />
            </div>
          </div>

        </div>

      </div> 

      <div>
      {/* Link to trigger PDF generation */}
      <a href="#" onClick={handleRmaClick} style={{ textDecoration: "underline", color: "blue", cursor: "pointer" ,font:"bold"}}>
        RMA Form (RMA-HS)
      </a>

      {/* Hidden RMA Component - Renders in the background */}
      <div style={{ position: "absolute", left: "-9999px", opacity: 0 }}>
        <RMA ref={rmaRef} infotipData={detailedData1} />
      </div>
    </div>
    </div>
  );
}

export default HomeVisit;
